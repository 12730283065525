import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TranslationService {

  public data: any = {};
  public loadingData = new BehaviorSubject(false);
  public currentLanguage = new BehaviorSubject(null);

  public languages = [
    {
      label: 'Castellano',
      key: 'es',
      img: 'assets/lang/es.png'
    },
    {
      label: 'English',
      key: 'en',
      img: 'assets/lang/en.png'
    },
  ]

  constructor(private http: HttpClient) {
    
  }

  getLoadingLanguage() {
    return this.loadingData.asObservable();
  }

  public getCurrentLanguage() {
    return this.currentLanguage.asObservable();
  }

  public getAllLanguages() {
    return this.languages;
  }

  setCurrentLanguage(key) {
    let r = this.languages.find(p => p.key == key);
    if(r) {
      this.currentLanguage.next(r)
    }
  }

  use(lang: string): Promise<{}> {
    this.loadingData.next(true);
    return new Promise<{}>(resolve => {
      const langPath = `./assets/lang/${lang || 'en'}.json`;
      this.setCurrentLanguage(lang || 'en')
      this.http.get(langPath).subscribe(
        response => {
          localStorage.setItem('belender-language', lang);
          this.data = response || {};
          this.loadingData.next(false);
          resolve(this.data);
        },
        err => {
          this.data = {};
          resolve(this.data);
          this.loadingData.next(false);
        }
      );
    });
  }
}
<div [class.boxed]="false" [class.horizontal-layout]="!(isLayoutVertical$ | async)"
  [class.is-mobile]="!(isDesktop$ | async)" [class.vertical-layout]="isLayoutVertical$ | async"
  [class.has-footer]="false" [class.scroll-disabled]="scrollDisabled$ | async" [class.toolbar-fixed]="true"
  [class.sidenav-collapsed]="sidenavCollapsed$ | async" [class.content-container]="containerEnabled$ | async"
  [class.with-search]="searchOpen$ | async" class="page-container">

  <vex-progress-bar></vex-progress-bar>

  <vex-search></vex-search>

  <mat-sidenav-container class="sidenav-container ">
    <mat-sidenav #sidenav [disableClose]="isDesktop$ | async" [fixedInViewport]="!(isDesktop$ | async)"
      [mode]="!(isDesktop$ | async) || (isLayoutVertical$ | async) ? 'over' : 'side'"
      [opened]="(isDesktop$ | async) && !(isLayoutVertical$ | async)" class="sidenav">
      <ng-container *ngTemplateOutlet="sidenavRef"></ng-container>
    </mat-sidenav>

    <mat-sidenav-content class="sidenav-content belender-scroll-content">
      <ng-container *ngTemplateOutlet="toolbarRef"></ng-container>

      <main class="content bg-slate-100">
        <router-outlet></router-outlet>
      </main>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PageService {
    
    public scrollTop: BehaviorSubject<number> = new BehaviorSubject(null);

    constructor() {
        
    }

    public getScrollTop() {
        return this.scrollTop.asObservable();
    }

    public setScrollTop(e) {
        this.scrollTop.next(+e);
    }
}

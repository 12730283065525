import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { getDatabase, ref, child, get, set } from "firebase/database";
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FetchService } from './fetch.service';

@Injectable({
    providedIn: 'root'
})
export class DocumentsService {

    db = getDatabase();
    httpOptionsHeaders: any;

    harcodedPacks = [
        {
            "id": "0",
            "flow": "0",
            "documents": [
                "Laboral",
                "SituacionSS",
                "Cotizacion",
                "Revalorizacion",
                "Pensiones",
                "SituacionAEAT",
                "SituacionAEATData",
                "Renta",
                "Censo",
                "TarjetaNIF",
                "100_2022",
                "100_2021",
                "100_2020",
                "111",
                "130",
                "131",
                "190",
                "303",
                "347",
                "390",
                "CIRBE",
                "Inmuebles"
            ]
        },
        {
            "id": "1",
            "flow": "0",
            "documents": [
                "Laboral",
                "SituacionSS",
                "Cotizacion",
                "Revalorizacion",
                "Pensiones",
                "SituacionAEAT",
                "SituacionAEATData",
                "Renta",
                "Censo",
                "TarjetaNIF",
                "100_2022",
                "100_2021",
                "100_2020",
                "CIRBE",
                "Inmuebles"
            ]
        },
        {
            "id": "4",
            "flow": "0",
            "documents": [
                "Laboral",
                "Cotizacion",
                "Renta",
                "100_2022",
                "390",
                "CIRBE",
                "Inmuebles"
            ]
        },
        {
            "id": "5",
            "flow": "0",
            "documents": [
                "200_2022",
                "390",
                "CIRBE"
            ]
        },
        {
            "id": "6",
            "flow": "0",
            "documents": [
                "SituacionSS",
                "TarjetaNIF",
                "CIRBE"
            ]
        },
        {
            "id": "7",
            "flow": "0",
            "documents": [
                "Renta",
                "TarjetaNIF"
            ]
        },
        {
            "id": "8",
            "flow": "0",
            "documents": [
                "SituacionSS",
                "TarjetaNIF"
            ]
        },
        {
            "id": "9",
            "flow": "0",
            "documents": [
                "Labooral",
                "SituacionSS",
                "Renta",
                "CertAEAT",
                "200_2022",
                "200_2021",
                "130_2022_4T",
                "130_2022_3T",
                "130_2022_2T",
                "130_2022_1T",
                "714",
                "347",
                "390"
            ]
        },
        {
            "id": "10",
            "flow": "0",
            "documents": [
                "Laboral",
                "Cotizacion",
                "Revalorizacion",
                "Pensiones",
                "Renta",
                "TarjetaNIF",
                "100_2022",
                "100_2021",
                "100_2020",
                "111",
                "130",
                "131",
                "190",
                "303",
                "347",
                "390",
                "CIRBE",
                "Inmuebles"
            ]
        },
        {
            "id": "11",
            "flow": "0",
            "documents": [
                "Laboral",
                "SituacionSS",
                "Cotizacion"
            ]
        },
        {
            "id": "12",
            "flow": "0",
            "documents": [
                "Laboral",
                "SituacionSS",
                "Cotizacion",
                "Pensiones",
                "SituacionAEAT",
                "SituacionAEATData",
                "Censo",
                "100_2022",
                "100_2021",
                "111",
                "130",
                "131",
                "190",
                "303",
                "347",
                "390"
            ]
        },
        {
            "id": "13",
            "flow": "0",
            "documents": [
                "SituacionSS",
                "Pensiones",
                "SituacionAEAT",
                "SituacionAEATData",
                "Censo",
                "CertAEAT",
                "200_2021",
                "190",
                "347",
                "390",
                "111",
                "130",
                "131",
                "303",
                "CIRBE",
                "Inmuebles"
            ]
        },
        {
            "id": "99",
            "flow": "0",
            "documents": [
                "CIRBE"
            ]
        },
        {
            "id": "100",
            "flow": "0",
            "documents": [
                "Laboral",
                "SituacionSS",
                "Cotizacion",
                "Revalorizacion",
                "Pensiones",
                "Renta",
                "TarjetaNIF",
                "CertAEAT",
                "100_2022",
                "100_2021",
                "130",
                "131"
            ]
        },
        {
            "id": "0",
            "flow": "1",
            "documents": [
                "Laboral",
                "SituacionSS",
                "Cotizacion",
                "Revalorizacion",
                "Pensiones",
                "SituacionAEAT",
                "SituacionAEATData",
                "Renta",
                "Censo",
                "TarjetaNIF",
                "100_2022",
                "100_2021",
                "100_2020",
                "111",
                "130",
                "131",
                "190",
                "303",
                "347",
                "390",
                "CIRBE",
                "Inmuebles"
            ]
        },
        {
            "id": "1",
            "flow": "1",
            "documents": [
                "Laboral",
                "SituacionSS",
                "Cotizacion",
                "Revalorizacion",
                "Pensiones",
                "SituacionAEAT",
                "SituacionAEATData",
                "Renta",
                "Censo",
                "TarjetaNIF",
                "100_2022",
                "100_2021",
                "100_2020",
                "CIRBE",
                "Inmuebles"
            ]
        },
        {
            "id": "4",
            "flow": "1",
            "documents": [
                "Laboral",
                "Cotizacion",
                "Renta",
                "100_2022",
                "390",
                "CIRBE",
                "Inmuebles"
            ]
        },
        {
            "id": "5",
            "flow": "1",
            "documents": [
                "200_2022",
                "390",
                "CIRBE"
            ]
        },
        {
            "id": "6",
            "flow": "1",
            "documents": [
                "SituacionSS",
                "TarjetaNIF",
                "CIRBE"
            ]
        },
        {
            "id": "7",
            "flow": "1",
            "documents": [
                "Renta",
                "TarjetaNIF"
            ]
        },
        {
            "id": "8",
            "flow": "1",
            "documents": [
                "SituacionSS",
                "TarjetaNIF"
            ]
        },
        {
            "id": "9",
            "flow": "1",
            "documents": [
                "Labooral",
                "SituacionSS",
                "Renta",
                "CertAEAT",
                "200_2022",
                "200_2021",
                "130_2022_4T",
                "130_2022_3T",
                "130_2022_2T",
                "130_2022_1T",
                "714",
                "347",
                "390"
            ]
        },
        {
            "id": "10",
            "flow": "1",
            "documents": [
                "Laboral",
                "Cotizacion",
                "Revalorizacion",
                "Pensiones",
                "Renta",
                "TarjetaNIF",
                "100_2022",
                "100_2021",
                "100_2020",
                "111",
                "130",
                "131",
                "190",
                "303",
                "347",
                "390",
                "CIRBE",
                "Inmuebles"
            ]
        },
        {
            "id": "11",
            "flow": "1",
            "documents": [
                "Laboral",
                "SituacionSS",
                "Cotizacion"
            ]
        },
        {
            "id": "12",
            "flow": "1",
            "documents": [
                "Laboral",
                "SituacionSS",
                "Cotizacion",
                "Pensiones",
                "SituacionAEAT",
                "SituacionAEATData",
                "Censo",
                "100_2022",
                "100_2021",
                "111",
                "130",
                "131",
                "190",
                "303",
                "347",
                "390"
            ]
        },
        {
            "id": "13",
            "flow": "1",
            "documents": [
                "SituacionSS",
                "Pensiones",
                "SituacionAEAT",
                "SituacionAEATData",
                "Censo",
                "CertAEAT",
                "200_2021",
                "190",
                "347",
                "390",
                "111",
                "130",
                "131",
                "303",
                "CIRBE",
                "Inmuebles"
            ]
        },
        {
            "id": "99",
            "flow": "1",
            "documents": [
                "CIRBE"
            ]
        },
        {
            "id": "100",
            "flow": "1",
            "documents": [
                "Laboral",
                "SituacionSS",
                "Cotizacion",
                "Revalorizacion",
                "Pensiones",
                "Renta",
                "TarjetaNIF",
                "CertAEAT",
                "100_2022",
                "100_2021",
                "130",
                "131"
            ]
        },
        {
            "id": "0",
            "flow": "2",
            "documents": [
                "Laboral",
                "SituacionSS",
                "Cotizacion",
                "Revalorizacion",
                "Pensiones",
                "SituacionAEAT",
                "SituacionAEATData",
                "Renta",
                "Censo",
                "TarjetaNIF",
                "100_2022",
                "100_2021",
                "100_2020",
                "111",
                "130",
                "131",
                "190",
                "303",
                "347",
                "390",
                "CIRBE",
                "Inmuebles"
            ]
        },
        {
            "id": "1",
            "flow": "2",
            "documents": [
                "Laboral",
                "SituacionSS",
                "Cotizacion",
                "Revalorizacion",
                "Pensiones",
                "SituacionAEAT",
                "SituacionAEATData",
                "Renta",
                "Censo",
                "TarjetaNIF",
                "100_2022",
                "100_2021",
                "100_2020",
                "CIRBE",
                "Inmuebles"
            ]
        },
        {
            "id": "4",
            "flow": "2",
            "documents": [
                "Laboral",
                "Cotizacion",
                "Renta",
                "100_2022",
                "390",
                "CIRBE",
                "Inmuebles"
            ]
        },
        {
            "id": "5",
            "flow": "2",
            "documents": [
                "200_2022",
                "390",
                "CIRBE"
            ]
        },
        {
            "id": "6",
            "flow": "2",
            "documents": [
                "SituacionSS",
                "TarjetaNIF",
                "CIRBE"
            ]
        },
        {
            "id": "7",
            "flow": "2",
            "documents": [
                "Renta",
                "TarjetaNIF"
            ]
        },
        {
            "id": "8",
            "flow": "2",
            "documents": [
                "SituacionSS",
                "TarjetaNIF"
            ]
        },
        {
            "id": "9",
            "flow": "2",
            "documents": [
                "Labooral",
                "SituacionSS",
                "Renta",
                "CertAEAT",
                "200_2022",
                "200_2021",
                "130_2022_4T",
                "130_2022_3T",
                "130_2022_2T",
                "130_2022_1T",
                "714",
                "347",
                "390"
            ]
        },
        {
            "id": "10",
            "flow": "2",
            "documents": [
                "Laboral",
                "Cotizacion",
                "Revalorizacion",
                "Pensiones",
                "Renta",
                "TarjetaNIF",
                "100_2022",
                "100_2021",
                "100_2020",
                "111",
                "130",
                "131",
                "190",
                "303",
                "347",
                "390",
                "CIRBE",
                "Inmuebles"
            ]
        },
        {
            "id": "11",
            "flow": "2",
            "documents": [
                "Laboral",
                "SituacionSS",
                "Cotizacion"
            ]
        },
        {
            "id": "12",
            "flow": "2",
            "documents": [
                "Laboral",
                "SituacionSS",
                "Cotizacion",
                "Pensiones",
                "SituacionAEAT",
                "SituacionAEATData",
                "Censo",
                "100_2022",
                "100_2021",
                "111",
                "130",
                "131",
                "190",
                "303",
                "347",
                "390"
            ]
        },
        {
            "id": "13",
            "flow": "2",
            "documents": [
                "SituacionSS",
                "Pensiones",
                "SituacionAEAT",
                "SituacionAEATData",
                "Censo",
                "CertAEAT",
                "200_2021",
                "190",
                "347",
                "390",
                "111",
                "130",
                "131",
                "303",
                "CIRBE",
                "Inmuebles"
            ]
        },
        {
            "id": "100",
            "flow": "2",
            "documents": [
                "Laboral",
                "SituacionSS",
                "Cotizacion",
                "Revalorizacion",
                "Pensiones",
                "Renta",
                "TarjetaNIF",
                "CertAEAT",
                "100_2022",
                "100_2021",
                "130",
                "131"
            ]
        },
        {
            "id": "0",
            "flow": "3",
            "documents": [
                "Laboral",
                "SituacionSS",
                "Cotizacion",
                "Revalorizacion",
                "Pensiones"
            ]
        },
        {
            "id": "1",
            "flow": "3",
            "documents": [
                "Laboral",
                "SituacionSS",
                "Cotizacion",
                "Revalorizacion",
                "Pensiones"
            ]
        },
        {
            "id": "6",
            "flow": "3",
            "documents": [
                "SituacionSS",
                "TarjetaNIF",
                "CIRBE"
            ]
        },
        {
            "id": "8",
            "flow": "3",
            "documents": [
                "SituacionSS"
            ]
        },
        {
            "id": "9",
            "flow": "3",
            "documents": [
                "Labooral",
                "SituacionSS"
            ]
        },
        {
            "id": "10",
            "flow": "3",
            "documents": [
                "Laboral",
                "Cotizacion",
                "Revalorizacion",
                "Pensiones"
            ]
        },
        {
            "id": "11",
            "flow": "3",
            "documents": [
                "Laboral",
                "SituacionSS",
                "Cotizacion"
            ]
        },
        {
            "id": "12",
            "flow": "3",
            "documents": [
                "Laboral",
                "SituacionSS",
                "Cotizacion",
                "Pensiones"
            ]
        },
        {
            "id": "100",
            "flow": "3",
            "documents": [
                "Laboral",
                "SituacionSS",
                "Cotizacion",
                "Revalorizacion",
                "Pensiones"
            ]
        },
        {
            "id": "0",
            "flow": "4",
            "documents": [
                "Laboral",
                "SituacionSS",
                "Cotizacion",
                "Revalorizacion",
                "Pensiones",
                "SituacionAEAT",
                "SituacionAEATData",
                "Renta",
                "Censo",
                "TarjetaNIF",
                "100_2022",
                "100_2021",
                "100_2020",
                "111",
                "130",
                "131",
                "190",
                "303",
                "347",
                "390",
                "CIRBE",
                "Inmuebles"
            ]
        },
        {
            "id": "1",
            "flow": "4",
            "documents": [
                "Laboral",
                "SituacionSS",
                "Cotizacion",
                "Revalorizacion",
                "Pensiones",
                "SituacionAEAT",
                "SituacionAEATData",
                "Renta",
                "Censo",
                "TarjetaNIF",
                "100_2022",
                "100_2021",
                "100_2020",
                "CIRBE",
                "Inmuebles"
            ]
        },
        {
            "id": "4",
            "flow": "4",
            "documents": [
                "Laboral",
                "Cotizacion",
                "Renta",
                "100_2022",
                "390",
                "CIRBE",
                "Inmuebles"
            ]
        },
        {
            "id": "5",
            "flow": "4",
            "documents": [
                "200_2022",
                "390",
                "CIRBE"
            ]
        },
        {
            "id": "6",
            "flow": "4",
            "documents": [
                "SituacionSS",
                "TarjetaNIF",
                "CIRBE"
            ]
        },
        {
            "id": "7",
            "flow": "4",
            "documents": [
                "Renta",
                "TarjetaNIF"
            ]
        },
        {
            "id": "8",
            "flow": "4",
            "documents": [
                "SituacionSS",
                "TarjetaNIF"
            ]
        },
        {
            "id": "9",
            "flow": "4",
            "documents": [
                "Labooral",
                "SituacionSS",
                "Renta",
                "CertAEAT",
                "200_2022",
                "200_2021",
                "130_2022_4T",
                "130_2022_3T",
                "130_2022_2T",
                "130_2022_1T",
                "714",
                "347",
                "390"
            ]
        },
        {
            "id": "10",
            "flow": "4",
            "documents": [
                "Laboral",
                "Cotizacion",
                "Revalorizacion",
                "Pensiones",
                "Renta",
                "TarjetaNIF",
                "100_2022",
                "100_2021",
                "100_2020",
                "111",
                "130",
                "131",
                "190",
                "303",
                "347",
                "390",
                "CIRBE",
                "Inmuebles"
            ]
        },
        {
            "id": "11",
            "flow": "4",
            "documents": [
                "Laboral",
                "SituacionSS",
                "Cotizacion"
            ]
        },
        {
            "id": "12",
            "flow": "4",
            "documents": [
                "Laboral",
                "SituacionSS",
                "Cotizacion",
                "Pensiones",
                "SituacionAEAT",
                "SituacionAEATData",
                "Censo",
                "100_2022",
                "100_2021",
                "111",
                "130",
                "131",
                "190",
                "303",
                "347",
                "390"
            ]
        },
        {
            "id": "13",
            "flow": "4",
            "documents": [
                "SituacionSS",
                "Pensiones",
                "SituacionAEAT",
                "SituacionAEATData",
                "Censo",
                "CertAEAT",
                "200_2021",
                "190",
                "347",
                "390",
                "111",
                "130",
                "131",
                "303",
                "CIRBE",
                "Inmuebles"
            ]
        },
        {
            "id": "99",
            "flow": "4",
            "documents": [
                "CIRBE"
            ]
        },
        {
            "id": "100",
            "flow": "4",
            "documents": [
                "Laboral",
                "SituacionSS",
                "Cotizacion",
                "Revalorizacion",
                "Pensiones",
                "Renta",
                "TarjetaNIF",
                "CertAEAT",
                "100_2022",
                "100_2021",
                "130",
                "131"
            ]
        }
    ]

    constructor(public utils: UtilsService, private http: HttpClient, private localFetch: FetchService) {}

    getDocumentPacks(funderID) {

        return new Promise((resolve, reject) => {
            let url = environment.baseUrl + '/funders/' + funderID + '/pack-documents';
            this.localFetch.getFetch(url).then(d => {
                if(d) {
                    resolve(d);
                } else {
                    reject(d);
                }
            }).catch(e => {
                reject(e)
            })
        }); 
    }

    getDocumentPacksByFlowId(flowId) {
        return new Promise((resolve, reject) => {
            let url = environment.baseUrl + '/flows/' + flowId + '/type-documents';
            this.localFetch.getFetch(url).then(d => {
                if(d) {
                    resolve(d);
                } else {
                    reject(d);
                }
            }).catch(e => {
                reject(e)
            })
        }); 
    }

    getAllDocumentsType() {
        return new Promise((resolve, reject) => {
            let url = environment.baseUrl + '/type-documents';
            this.localFetch.getFetch(url).then(d => {
                if(d) {
                    resolve(d);
                } else {
                    reject(d);
                }
            }).catch(e => {
                reject(e)
            })
        }); 
    }

    getDocumentPacksByUserID(userID) {
        return new Promise((resolve, reject) => {
            let url = environment.baseUrl + '/users/' + userID + '/pack-documents';
            this.localFetch.getFetch(url).then(d => {
                if(d) {
                    resolve(d);
                } else {
                    reject(d);
                }
            }).catch(e => {
                reject(e)
            })
        }); 
    }

    savePacks(funderID, obj) {
        return new Promise((resolve, reject) => {
            this.http.post(environment.baseUrl + '/funders/' + funderID + '/flows/pack-documents', obj).subscribe((d: any) => {
                if(d) {
                    resolve(d);
                } else {
                    reject(d);
                }
            }, e => {
                reject(e)
            });
        }); 
    }

    saveDocumentPack(obj) {
        return new Promise((resolve, reject) => {
            this.localFetch.postFetch(environment.baseUrl + '/funders/flows/type-documents', obj).then((d: any) => {
                if(d) {
                    resolve(d);
                } else {
                    reject(d);
                }
            }, e => {
                reject(e)
            });
        }); 
    }

    getAllDocumentPacks() {
        return new Promise((resolve, reject) => {
            this.localFetch.getFetch(environment.baseUrl + '/pack-documents').then((d: any) => {
                if(d) {
                    resolve({data: this.harcodedPacks});
                } else {
                    reject(d);
                }
            }, e => {
                resolve({data: this.harcodedPacks})
            });
        });
    }

    addDocument(obj) {
        return new Promise((resolve, reject) => {
            this.localFetch.postFetch(environment.baseUrl + '/type-documents', obj).then((d: any) => {
                if(d) {
                    resolve({data: this.harcodedPacks});
                } else {
                    reject(d);
                }
            }, e => {
                resolve({data: this.harcodedPacks})
            });
        });
    }

    saveDocument(obj, id) {
        return new Promise((resolve, reject) => {
            this.localFetch.putFetch(environment.baseUrl + '/type-documents/' + id , obj).then((d: any) => {
                if(d) {
                    resolve({data: this.harcodedPacks});
                } else {
                    reject(d);
                }
            }, e => {
                resolve({data: this.harcodedPacks})
            });
        });
    }

    addDocumentToFlow(flowId, documentId, obj) {
        return new Promise((resolve, reject) => {
            this.localFetch.postFetch(`${environment.baseUrl}/flows/${flowId}/type-documents/${documentId}'`, obj).then((d: any) => {
                if(d) {
                    resolve({data: this.harcodedPacks});
                } else {
                    reject(d);
                }
            }, e => {
                resolve({data: this.harcodedPacks})
            });
        });
    }
}

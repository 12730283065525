import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AF_AUTOSCRIPT } from './tokens';
import { AF_MODULE_SCRIPT } from './types';
import { AutofirmaService } from './autofirma.service';

declare const AutoScript: AF_MODULE_SCRIPT;

@NgModule({
  declarations: [],
  imports: [],
})
export class AutofirmaModule {
  public static forRoot(): ModuleWithProviders<AutofirmaModule> {
    return {
      ngModule: AutofirmaModule,
      providers: [
        {provide: AF_AUTOSCRIPT, useValue: AutoScript},
        AutofirmaService,
      ]
    }
  }
}

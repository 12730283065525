import { ChangeDetectorRef } from "@angular/core";
import { BehaviorSubject } from "rxjs";

// export class BelenderTranslation {

//   translation = {
//     es: {
//       "requests": {
//           "all_requests": "Todas las solicitudes",
//           "requests": "Solicitudes",
//           "new_request": "Nueva solicitud",
//           "load_history": "Cargar histórico",
//           "load_today": "Cargar sólo de hoy",
//           "download": "Descargar",
//           "search": "Buscar",
//           "in_progress": "En progreso",
//           "abandoned": "Abandonada",
//           "completed": "Completada",
//           "partially_completed": "Parc. completada",
//           "canceled": "Cancelada",
//           "failed": "Fallida",
//           "waiting": "En cola"
//       },
//       "edit_request": {
//           "request_from": "Solicitud vía",
//           "status": "Estado",
//           "type": "Tipo",
//           "physic": "Física",
//           "info": "Información",
//           "name": "Nombre",
//           "last_name": "Apellido",
//           "email": "Email",
//           "phone": "Teléfono",
//           "request_date": "Fecha de solicitud",
//           "answer_date": "Fecha de respuesta",
//           "attachments": "Archivos",
//           "add_attachment": "Agregar adjunto",
//           "in_progress": "En progreso",
//           "abandoned": "Abandonada",
//           "completed": "Completada",
//           "partially_completed": "Parcialmente completada",
//           "canceled": "Cancelada",
//           "failed": "Fallida",
//           "waiting": "En cola",
//           "waiting_login": "Esperando login",
//           "terms_and_conditions": "Términos y condiciones",
//           "created": "Creada",
//           "waiting_otp": "Esperando OTP",
//           "saved_otp": "OTP Guardado",
//           "failed_login_otp": "Falló login OTP",
//           "bad_date_sms_otp": "Fecha errónea en SMS OTP",
//           "updated_data": "Datos actualizados",
//           "updated_data_picked_up": "Datos levantados",
//           "max_entries_otp": "Error máximos intentos OTP",
//           "bad_phone_sms_otp": "Teléfono erróneo SMS OTP",
//           "bad_support_number_pin_otp": "Número de soporte erróneo pin OTP",
//           "otp_picked_up": "OTP levantado",
//           "phone_not_exist_otp": "El teléfono no existe",
//           "max_time_exceeded_pin_otp": "Máximo tiempo excedido",
//           "user_not_has_clave_pin_otp": "El Usuario no tiene clave PIN",
//           "error_sms_otp": "Error SMS OTP",
//           "error_pin_otp": "Error PIN OTP",
//           "error_otp": "Error OTP",
//           "waiting_documents": "Esperando documentos",
//           "all_documents_downloaded": "Todos los documentos descargados",
//           "parcial_documents_saved": "Algunos documentos guardados",
//           "failed_documents_downloaded": "Fallo al descargar documentos",
//           "picked_up_otp": "OTP levantado",
//           "retry_otp": "Reintento OTP",
//           "abandoned_after_waiting_otp_seguridad_social": "Abandonado luego de esperar OTP de Seguridad Social",
//           "abandoned_after_waiting_otp_aeat": "Abandonado luego de esperar OTP de AEAT",
//           "partial_documents_downloaded": "Algunos documentos descargados",
//           "waiting_login_aeat": "Esperando login en AEAT",
//           "waiting_login_ss": "Esperando login en Seguridad Social"
//       },
//       "actions": {
//       "cancel": "Cancelar",
//       "save": "Guardar"
//       },
//       "toolbar": {
//       "new": "Nueva",
//       "request": "Solicitud"
//       },
//       "sidenav": {
//       "all_requests": "Todas las solicitudes",
//       "requests": "Solicitudes",
//       "new_request": "Nueva solicitud",
//       "customization": "Customización",
//       "config": "Configuración"
//       },
//       "users": {
//         "title": "Usuarios",
//         "funders": "Financiadores",
//         "admins": "Administradores",
//         "collaborators": "Colaboradores",
//         "emptyFunders": "Ningun financiador seleccionado",
//         "emptyAdmins": "Ningun administrador seleccionado",
//         "emptyCollaborators": "Ningun colaborador seleccionado",
//         "emptyCollaboratorsAssigned": "Este financiador no tiene ningún colaborador asignado",
//         "emptAdminsAssigned": "Este financiador no tiene ningún administrador asignado",
//         "newFunder": {
//           "title": "Nuevo financiador",
//           "name": "Nombre",
//           "smsSender": "Emisor SMS",
//           "cancel": "Cancelar",
//           "createFunder": "Crear financiador"
//         },
//         "newAdmin": {
//           "title": "Nuevo administrador",
//           "name": "Nombre",
//           "email": "Correo electrónico",
//           "password": "Contraseña",
//           "repeatPassword": "Repita la contraseña",
//           "cancel": "Cancelar",
//           "createAdmin": "Crear administrador",
//           "passwordWarning": "El password debe tener al menos 8 carácteres, al menos una mayúscula y al menos un número"
//         },
//         "newCollaborator": {
//           "title": "Nuevo colaborador",
//           "name": "Nombre",
//           "email": "Correo electrónico",
//           "password": "Contraseña",
//           "repeatPassword": "Repita la contraseña",
//           "cancel": "Cancelar",
//           "createAdmin": "Crear colaborador",
//           "passwordWarning": "El password debe tener al menos 8 carácteres, al menos una mayúscula y al menos un número"
//         }
//       },
//       "customization": {
//         "title": "Customización",
//         "funder": "Financiador",
//         "flow": "Flujo del widget",
//         "saveChanges": "Guardar cambios",
//         "saving": "Guardando",
//         "selectUser": "Selecciona usuario",
//         "loadingFunders": "Cargando financiadores",
//         "loadingFunder": "Cargando financiador",
//         "emptyFunder": "No hay ningun financiador seleccionado",
//         "widget": {
//           "title": "Personalizar Widget",
//           "subTitle": "Colores predominantes en landing pages & emails de la empresa.",
//           "background": "Fondo",
//           "titles": "Titulos",
//           "text": "Textos",
//           "button": "Botón",
//           "buttonText": "Texto del botón",
//           "borderRadius": "Radio del botón",
//           "onlyOTP": "Sólo pantalla de OTP",
//           "errorColor": "Color de error",
//           "errorColorBg": "Color de error de fondo",
//           "errorButtonText": "Color del botón",
//           "accentColor": "Color del mensaje",
//           "accentColorBg": "Color de fondo del mensaje",
//           "moreInfo": "Más info",
//           "enableFollowEmail": "Habilitar email de seguimiento",

//           "withLogo": "Con logotipo",
//           "urlLogo": "Url logotipo",
//           "personalData": "Datos personales",
//           "otpSegSocial": "OTP Seg Social",
//           "completed": "Completado",
//           "dataError": "Error datos",
//           "failed": "Fallido",
//           "abandoned": "Abandonado",
//           "setTitle": "Título",
//           "setDescription": "Texto de la descripción"
//         }
//       }
//     },
//     en: {
//       "requests": {
//           "all_requests": "All request",
//           "requests": "Requests",
//           "new_request": "New request",
//           "load_history": "Load history",
//           "load_today": "Load only today",
//           "download": "Download",
//           "search": "Search",
//           "in_progress": "In progress",
//           "abandoned": "Abandoned",
//           "completed": "Completed",
//           "partially_completed": "Part. completed",
//           "canceled": "Canceled",
//           "failed": "Failed",
//           "waiting": "Waiting"
//       },
//       "edit_request": {
//         "request_from": "Request from",
//         "status": "Status",
//         "type": "Type",
//         "physic": "Physic",
//         "info": "Information",
//         "name": "Name",
//         "last_name": "Last name",
//         "email": "Email",
//         "phone": "Phone",
//         "request_date": "Request date",
//         "answer_date": "Answer date",
//         "attachments": "Files",
//         "add_attachment": "Add attachment",
//         "in_progress": "In progress",
//         "abandoned": "Abandoned",
//         "completed": "Completed",
//         "partially_completed": "Partially completed",
//         "canceled": "Canceled",
//         "failed": "Failed",
//         "waiting": "Waiting",
//         "waiting_login": "Waiting login",
//         "terms_and_conditions": "Terms and conditions",
//         "created": "Created",
//         "waiting_otp": "Waiting OTP",
//         "saved_otp": "OTP Saved",
//         "failed_login_otp": "Failed login OTP",
//         "bad_date_sms_otp": "Bad date in SMS OTP",
//         "updated_data": "Data updated",
//         "updated_data_picked_up": "Data picked up",
//         "max_entries_otp": "Error max entries OTP",
//         "bad_phone_sms_otp": "Bad phone SMS OTP",
//         "bad_support_number_pin_otp": "Bad support number pin OTP",
//         "otp_picked_up": "OTP picked up",
//         "phone_not_exist_otp": "Phone not exist",
//         "max_time_exceeded_pin_otp": "Max time exceeded",
//         "user_not_has_clave_pin_otp": "User not has clave PIN",
//         "error_sms_otp": "Error SMS OTP",
//         "error_pin_otp": "Error PIN OTP",
//         "error_otp": "Error OTP",
//         "waiting_documents": "Waiting documents",
//         "all_documents_downloaded": "All documents downloaded",
//         "parcial_documents_saved": "Parcial documents saved",
//         "failed_documents_downloaded": "Failed documents downloaded",
//         "picked_up_otp": "Picked up OTP",
//         "retry_otp": "Retry OTP"
//       },
//       "actions": {
//         "cancel": "Cancel",
//         "save": "Save"
//       },
//       "toolbar": {
//         "new": "New",
//         "request": "Request"
//       },
//       "sidenav": {
//         "all_requests": "All request",
//         "requests": "Requests",
//         "new_request": "New request",
//         "customization": "Customization",
//         "config": "Settings"
//       },
//       "users": {
//         "title": "Users",
//         "funders": "Funders",
//         "admins": "Admins",
//         "collaborators": "Collaborators",
//         "emptyFunders": "No funder selected",
//         "emptyAdmins": "No admin selected",
//         "emptyCollaborators": "No collaborator selected",
//         "emptyCollaboratorsAssigned": "This funder has no collaborator assigned",
//         "emptAdminsAssigned": "This funder has no admins assigned",
//         "newFunder": {
//           "title": "New funder",
//           "name": "Name",
//           "smsSender": "SMS sender",
//           "cancel": "Cancel",
//           "createFunder": "Create funder"
//         },
//         "newAdmin": {
//           "title": "New admin",
//           "name": "Name",
//           "email": "Email",
//           "password": "Password",
//           "repeatPassword": "Repeat password",
//           "cancel": "Cancel",
//           "createAdmin": "Create admin",
//           "passwordWarning": "The password must have at least 8 characters, at least one capital letter and at least one number"
//         },
//         "newCollaborator": {
//           "title": "New collaborator",
//           "name": "Name",
//           "email": "Email",
//           "password": "Password",
//           "repeatPassword": "Repeat password",
//           "cancel": "Cancel",
//           "createAdmin": "Create collaborator",
//           "passwordWarning": "The password must have at least 8 characters, at least one capital letter and at least one number"
//         }
//       },
//       "customization": {
//         "title": "Customization",
//         "funder": "Funder",
//         "flow": "Widget flow",
//         "saveChanges": "Save changes",
//         "saving": "Saving",
//         "selectUser": "Select user",
//         "loadingFunders": "Loading funders",
//         "loadingFunder": "Loading funder",
//         "emptyFunder": "No funder selected",
//         "widget": {
//           "title": "Customizate Widget",
//           "subTitle": "Predominant colors in landing pages & emails of the company.",
//           "background": "Background",
//           "titles": "Titles",
//           "text": "Texts",
//           "button": "Button",
//           "buttonText": "Button text",
//           "borderRadius": "Button radius",
//           "onlyOTP": "Only OTP screen",
//           "errorColor": "Error main color",
//           "errorColorBg": "Error background color",
//           "errorButtonText": "Button color",
//           "accentColor": "Message main color",
//           "accentColorBg": "Message background color",
//           "moreInfo": "More info",
//           "enableFollowEmail": "Enable Follow-up Email",
//           "withLogo": "With logo",
//           "urlLogo": "Url logo",
//           "personalData": "Personal data",
//           "otpSegSocial": "OTP Social Sec",
//           "completed": "Completed",
//           "dataError": "Data error",
//           "failed": "Failed",
//           "abandoned": "Abandoned",
//           "setTitle": "Title",
//           "setDescription": "Description text"
//         }
//       }
//     }
//   }

//   public languages = [
//     {
//       key: 'es',
//       img: 'assets/lang/es.png',
//       label: 'Castellano'
//     },
//     {
//       key: 'en',
//       img: 'assets/lang/en.png',
//       label: 'English'
//     }
//   ];

//   localSelectedLanguage = localStorage.getItem('belender-language');
//   public currentTranslation =  this.translation[this.localSelectedLanguage];
//   public currentLanguage = this.languages.find(p => p.key == this.localSelectedLanguage);

//   constructor() {
//     if(this.localSelectedLanguage) {
//       this.currentTranslation =  this.translation[this.localSelectedLanguage];
//     } else {
//       this.currentTranslation =  this.translation.es;
//     }
//   }

//   public setCurrentLanguage(key) {
//     let r = this.languages.find(p => p.key == key);
//     if(r) {
      
//       this.currentLanguage = r;
//       localStorage.setItem('belender-language', key);
//       this.currentTranslation = this.translation[key];
//       console.log('setCurrentLanguage: ', this.currentTranslation);
//       //this.cd.markForCheck();
//     }
//   }

//   public getLanguage(key) {
//     let r = this.languages.find(p => p.key == key);
//     if(r) {
//       return r;
//     }
//   }
  
//   public getCurrentLanguage() {
//     return this.currentLanguage;
//   }
  
//   public getAllLanguages() {
//     return this.languages;
//   }

// }

// export const TRANSLATION = new BelenderTranslation();



  export const translation = {
    es: {
      "requests": {
          "all": {
            "title": "Todas las solicitudes",
            "last_update": "Última actualización",
            "new_request": "Nueva solicitud",
            "table": {
              "filters": "Filtros",
              "search": "Buscar",
              "refresh": "Refrescar",
              "download": "Descargar",
              "hide_columns": "Ocultar/mostrar columnas",
              "funder": "Financiador",
              "agent": "Agente",
              "dni": "DNI",
              "request_id": "Id de solicitud",
              "from": "Desde",
              "to": "Hasta",
              "filter": "Filtrar",
              "created": "Creada",
              "type": "Tipo",
              "state": "Estado",
              "empty_documents": "Vaciar documentos",
              "view_documents": "Ver documentos",
              "copy_widget_url": "Copiar url del widget",
              "phone": "Teléfono",
              "response_date": "F. de respuesta",
              "actions": "Acciones",
              "empty_request_for_user": "No hay solicitudes para el usuario"
            }
          },
          "new": {
            "person": {
              "step": "Persona",
              "title": "Tipo de persona",
              "description": "Debes definir si el tipo de persona es jurídica o física",
              "physic": "Física",
              "judge": "Jurídica"
            },
            "flow": {
              "step": "Flujo"
            },
            "packs": {
              "step": "Packs",
              "autorization": "Autorización",
              "title": "Pack documentales",
              "description": "Elige que pack documental desea descargar en esta solicitud"
            },
            "form": {
              "step": "Formulario"
            },
            "password": {
              "step": "Contraseña"
            },
            "finish": {
              "step": "Finalizar",
              "send_sms_and_finish": "Enviar SMS y finalizar",
              "send_sms_to_user": "Enviar SMS al usuario",
              "phone": "Teléfono"
            },
            "actions": {
              "next": "Siguiente",
              "previous": "Anterior",
              "go_to_request_list": "Ir a la lista de solicitudes",
              
            }
          }
      },
      "edit_request": {
          "request_from": "Solicitud vía",
          "status": "Estado",
          "type": "Tipo",
          "physic": "Física",
          "info": "Información",
          "name": "Nombre",
          "last_name": "Apellido",
          "email": "Email",
          "phone": "Teléfono",
          "request_date": "Fecha de solicitud",
          "answer_date": "Fecha de respuesta",
          "attachments": "Archivos",
          "add_attachment": "Agregar adjunto",
          "in_progress": "En progreso",
          "abandoned": "Abandonada",
          "completed": "Completada",
          "partially_completed": "Parcialmente completada",
          "canceled": "Cancelada",
          "failed": "Fallida",
          "waiting": "En cola",
          "waiting_login": "Esperando login",
          "terms_and_conditions": "Términos y condiciones",
          "created": "Creada",
          "waiting_otp": "Esperando OTP",
          "saved_otp": "OTP Guardado",
          "failed_login_otp": "Falló login OTP",
          "bad_date_sms_otp": "Fecha errónea en SMS OTP",
          "updated_data": "Datos actualizados",
          "updated_data_picked_up": "Datos levantados",
          "max_entries_otp": "Error máximos intentos OTP",
          "bad_phone_sms_otp": "Teléfono erróneo SMS OTP",
          "bad_support_number_pin_otp": "Número de soporte erróneo pin OTP",
          "otp_picked_up": "OTP levantado",
          "phone_not_exist_otp": "El teléfono no existe",
          "max_time_exceeded_pin_otp": "Máximo tiempo excedido",
          "user_not_has_clave_pin_otp": "El Usuario no tiene clave PIN",
          "error_sms_otp": "Error SMS OTP",
          "error_pin_otp": "Error PIN OTP",
          "error_otp": "Error OTP",
          "waiting_documents": "Esperando documentos",
          "all_documents_downloaded": "Todos los documentos descargados",
          "parcial_documents_saved": "Algunos documentos guardados",
          "failed_documents_downloaded": "Fallo al descargar documentos",
          "picked_up_otp": "OTP levantado",
          "retry_otp": "Reintento OTP",
          "abandoned_after_waiting_otp_seguridad_social": "Abandonado luego de esperar OTP de Seguridad Social",
          "abandoned_after_waiting_otp_aeat": "Abandonado luego de esperar OTP de AEAT",
          "partial_documents_downloaded": "Algunos documentos descargados",
          "waiting_login_aeat": "Esperando login en AEAT",
          "waiting_login_ss": "Esperando login en Seguridad Social"
      },
      "actions": {
      "cancel": "Cancelar",
      "save": "Guardar"
      },
      "toolbar": {
      "new": "Nueva",
      "request": "Solicitud"
      },
      "sidenav": {
        "all_request": "Todas las solicitudes",
        "admin_config": "Admininistrador",
        "requests": "Solicitudes",
        "new_request": "Nueva solicitud",
        "widget": "Widget",
        "customization": "Customización",
        "config": "Configuración",
        "packs": "Packs documentales",
        "users": "Usuarios",
        "customizate": "Customizar",
        "form": "Formulario",
        "id_validation": "Emitir certificado",
        "auth_validation": "Certificados"
      },
      "id_validation": {
        "title": "Emitir certificado",
        "table": {
          "created": "Fecha de creación",
          "funder": "Funder",
          "type": "Tipo de solicitud",
          "actions": "Acciones"
        }
      },
      "auth_validation": {
        "title": "Certificados emitidos",
        "table": {
          "created": "Fecha de creación",
          "funder": "Funder",
          "type": "Tipo de solicitud",
          "actions": "Acciones"
        }
      },
      "users": {
        "title": "Usuarios",
        "funders": "Financiadores",
        "admins": "Administradores",
        "collaborators": "Colaboradores",
        "emptyFunders": "Ningun financiador seleccionado",
        "emptyAdmins": "Ningun administrador seleccionado",
        "emptyCollaborators": "Ningun colaborador seleccionado",
        "emptyCollaboratorsAssigned": "Este financiador no tiene ningún colaborador asignado",
        "emptAdminsAssigned": "Este financiador no tiene ningún administrador asignado",
        "newFunder": {
          "title": "Nuevo financiador",
          "name": "Nombre",
          "smsSender": "Emisor SMS",
          "cancel": "Cancelar",
          "createFunder": "Crear financiador"
        },
        "newAdmin": {
          "title": "Nuevo administrador",
          "name": "Nombre",
          "email": "Correo electrónico",
          "password": "Contraseña",
          "repeatPassword": "Repita la contraseña",
          "cancel": "Cancelar",
          "createAdmin": "Crear administrador",
          "passwordWarning": "El password debe tener al menos 8 carácteres, al menos una mayúscula y al menos un número"
        },
        "editAdmin": {
          "title": "Editar administrador",
          "saveChanges": "Guardar cambios"
        },
        "newCollaborator": {
          "title": "Nuevo colaborador",
          "name": "Nombre",
          "email": "Correo electrónico",
          "password": "Contraseña",
          "repeatPassword": "Repita la contraseña",
          "cancel": "Cancelar",
          "createCollaborator": "Crear colaborador",
          "passwordWarning": "El password debe tener al menos 8 carácteres, al menos una mayúscula y al menos un número"
        },
        "editCollaborator": {
          "title": "Editar colaborador",
          "saveChanges": "Guardar cambios"
        }
      },
      "customization": {
        "title": "Customización",
        "funder": "Financiador",
        "flow": "Flujo del widget",
        "loadingFlows": "Cargando flujos",
        "saveChanges": "Guardar cambios",
        "saving": "Guardando",
        "savingChanges": "Guardando cambios",
        "selectUser": "Selecciona usuario",
        "flows": "Flujo",
        "loadingFunders": "Cargando financiadores",
        "loadingFunder": "Cargando financiador",
        "emptyFunder": "No hay ningun financiador seleccionado",
        "funderEdit": "Datos del funder",
        "funderEditDescription": "Editar datos del financiador",
        "widget": {
          "title": "Personalizar Widget",
          "subTitle": "Colores predominantes en landing pages & emails de la empresa.",
          "background": "Fondo",
          "titles": "Titulos",
          "text": "Textos",
          "button": "Botón",
          "buttonText": "Texto del botón",
          "borderRadius": "Radio del botón",
          "onlyOTP": "Sólo pantalla de OTP",
          "errorColor": "Color de error",
          "errorColorBg": "Color de error de fondo",
          "errorButtonText": "Color del botón",
          "accentColor": "Color del mensaje",
          "accentColorBg": "Color de fondo del mensaje",
          "moreInfo": "Más info",
          "enableFollowEmail": "Habilitar email de seguimiento",
          "enableFollowEmailBackoffice": "Habilitar email de documentación",
          "withLogo": "Con logotipo",
          "urlLogo": "Url logotipo",
          "personalData": "Datos personales",
          "otpSegSocial": "OTP Seg Social",
          "completed": "Completado",
          "dataError": "Error datos",
          "failed": "Fallido",
          "abandoned": "Abandonado",
          "setTitle": "Título",
          "titleFontHeight": "Tamaño de fuente del título",
          "setDescription": "Texto de la descripción",
          "dataAssignmentCheck": "Check de cesión de datos",
          "dataAssignmentLabel": "Texto de la cesión de datos",
          "viewCheckLegalMandatory": "Forzar a leer los check legales",
          "infiniteLoopInCompleted": "No mostrar pantalla de completado",
          "dontShowDataInTerms": "No mostrar información del usuario en términos y condiciones",
          "disableRetryPhone": "No permitir seguir en teléfono erróneo",
          "hideCheckLegal": "No mostrar check legal de servicios",
          "tryPinWithoutSMS": "Intentar Clave Pin cuando no tiene SMS",
          "activatePower": "Activar poder digital"
        },
        "urls": {
          "title": "Links del Widget",
          "description": "Estas son las distintas urls que puedes utilizar para el widget segun tus requerimientos",
          "no_packs_for": "No hay packs para el flujo ",
          "no_pack_selected": "Debes elegir un pack documental para poder generar la Url del widget",
          "user": "Usuario",
          "document_packs": "Pack de documentos",
          "sms_pack": "Pack documental SMS",
          "pin_pack": "Pack documental ClavePIN",
          "link_copied": "Enlace copiado al portapapeles"
        },
        "terms": {
          "title": "Términos y condiciones flujo "
        },
        "policy": {
          "title": "Aviso legal"
        },
        "notifications": {
          "title": "Notificaciones",
          "description": "Configura la url y el email donde se enviaran todas las notificaciones"
        },
        "otp": {
          "title": "Selección de orden OTP",
          "description": "Seleccione el OTP que se pedirá primero",
          "select_otp": "Portal OTP"
        }
      },
      "loaders": {
        "loading_request": "Cargando solicitudes",
        "deleting_documents": "Eliminando documentos",
        "documents_deleted": "Documentos eliminados correctamente",
        "documents_not_deleted": "No se pudieron eliminar los documentos",
        "new_request_added": "Nueva solicitud agregada",
        "link_copied": "Enlace copiado al portapapeles"
      }
    },
    en: {
      "requests": {
          "all": {
            "title": "All request",
            "last_update": "Last update",
            "new_request": "New request",
            "table": {
              "filters": "Filters",
              "search": "Search",
              "refresh": "Refresh",
              "download": "Download",
              "hide_columns": "Show/hide columns",
              "funder": "Funder",
              "agent": "Agent",
              "dni": "DNI",
              "request_id": "Request Id",
              "from": "From",
              "to": "To",
              "filter": "Filter",
              "created": "Created",
              "type": "Type",
              "state": "State",
              "empty_documents": "Empty documents",
              "view_documents": "View documents",
              "copy_widget_url": "Copy widget url",
              "phone": "Phone",
              "response_date": "Response date",
              "actions": "Actions",
              "empty_request_for_user": "No request for the user"
            }
          },
          "new": {
            "person": {
              "step": "Person",
              "title": "Persona type",
              "description": "You must define if the type of person is legal or personal",
              "physic": "Personal",
              "judge": "Legal"
            },
            "flow": {
              "step": "Flow"
            },
            "packs": {
              "step": "Packs",
              "autorization": "Autorization",
              "title": "Document Packs",
              "description": "Choose which documentary pack you want to download in this request"
            },
            "form": {
              "step": "Form"
            },
            "password": {
              "step": "Contraseña"
            },
            "finish": {
              "step": "Finish",
              "send_sms_and_finish": "Send SMS and finish",
              "send_sms_to_user": "Send SMS to user",
              "phone": "Phone"
            },
            "actions": {
              "next": "Next",
              "previous": "Previous",
              "go_to_request_list": "Go to request list"
            }
          }
      },
      "edit_request": {
        "request_from": "Request from",
        "status": "Status",
        "type": "Type",
        "physic": "Physic",
        "info": "Information",
        "name": "Name",
        "last_name": "Last name",
        "email": "Email",
        "phone": "Phone",
        "request_date": "Request date",
        "answer_date": "Answer date",
        "attachments": "Files",
        "add_attachment": "Add attachment",
        "in_progress": "In progress",
        "abandoned": "Abandoned",
        "completed": "Completed",
        "partially_completed": "Partially completed",
        "canceled": "Canceled",
        "failed": "Failed",
        "waiting": "Waiting",
        "waiting_login": "Waiting login",
        "terms_and_conditions": "Terms and conditions",
        "created": "Created",
        "waiting_otp": "Waiting OTP",
        "saved_otp": "OTP Saved",
        "failed_login_otp": "Failed login OTP",
        "bad_date_sms_otp": "Bad date in SMS OTP",
        "updated_data": "Data updated",
        "updated_data_picked_up": "Data picked up",
        "max_entries_otp": "Error max entries OTP",
        "bad_phone_sms_otp": "Bad phone SMS OTP",
        "bad_support_number_pin_otp": "Bad support number pin OTP",
        "otp_picked_up": "OTP picked up",
        "phone_not_exist_otp": "Phone not exist",
        "max_time_exceeded_pin_otp": "Max time exceeded",
        "user_not_has_clave_pin_otp": "User not has clave PIN",
        "error_sms_otp": "Error SMS OTP",
        "error_pin_otp": "Error PIN OTP",
        "error_otp": "Error OTP",
        "waiting_documents": "Waiting documents",
        "all_documents_downloaded": "All documents downloaded",
        "parcial_documents_saved": "Parcial documents saved",
        "failed_documents_downloaded": "Failed documents downloaded",
        "picked_up_otp": "Picked up OTP",
        "retry_otp": "Retry OTP"
      },
      "actions": {
        "cancel": "Cancel",
        "save": "Save"
      },
      "toolbar": {
        "new": "New",
        "request": "Request"
      },
      "sidenav": {
        "all_request": "All request",
        "admin_config": "Admin",
        "requests": "Requests",
        "new_request": "New request",
        "widget": "Widget",
        "customization": "Customization",
        "config": "Settings",
        "packs": "Document packs",
        "users": "Users",
        "customizate": "Customizate",
        "form": "Form",
        "id_validation": "Emit certificate",
        "auth_validation": "Certificates"
      },
      "id_validation": {
        "title": "Emit certificate",
        "table": {
          "created": "Created date",
          "funder": "Funder",
          "type": "Request type",
          "actions": "Actions"
        }
      },
      "auth_validation": {
        "title": "Certificates",
        "table": {
          "created": "Created date",
          "funder": "Funder",
          "type": "Request type",
          "actions": "Actions"
        }
      },
      "users": {
        "title": "Users",
        "funders": "Funders",
        "admins": "Admins",
        "collaborators": "Collaborators",
        "emptyFunders": "No funder selected",
        "emptyAdmins": "No admin selected",
        "emptyCollaborators": "No collaborator selected",
        "emptyCollaboratorsAssigned": "This funder has no collaborator assigned",
        "emptAdminsAssigned": "This funder has no admins assigned",
        "newFunder": {
          "title": "New funder",
          "name": "Name",
          "smsSender": "SMS sender",
          "cancel": "Cancel",
          "createFunder": "Create funder"
        },
        "newAdmin": {
          "title": "New admin",
          "name": "Name",
          "email": "Email",
          "password": "Password",
          "repeatPassword": "Repeat password",
          "cancel": "Cancel",
          "createAdmin": "Create admin",
          "passwordWarning": "The password must have at least 8 characters, at least one capital letter and at least one number"
        },
        "editAdmin": {
          "title": "Edit admin",
          "saveChanges": "Save changes"
        },
        "newCollaborator": {
          "title": "New collaborator",
          "name": "Name",
          "email": "Email",
          "password": "Password",
          "repeatPassword": "Repeat password",
          "cancel": "Cancel",
          "createCollaborator": "Create collaborator",
          "passwordWarning": "The password must have at least 8 characters, at least one capital letter and at least one number"
        },
        "editCollaborator": {
          "title": "Edit collaborator",
          "saveChanges": "Save changes"
        }
      },
      "customization": {
        "title": "Customization",
        "funder": "Funder",
        "flow": "Widget flow",
        "saveChanges": "Save changes",
        "saving": "Saving",
        "savingChanges": "Saving changes",
        "selectUser": "Select user",
        "flows": "Flow",
        "loadingFlows": "Loading flows",
        "loadingFunders": "Loading funders",
        "loadingFunder": "Loading funder",
        "emptyFunder": "No funder selected",
        "funderEdit": "Funder data",
        "funderEditDescription": "Edit funder data",
        "widget": {
          "title": "Customizate Widget",
          "subTitle": "Predominant colors in landing pages & emails of the company.",
          "background": "Background",
          "titles": "Titles",
          "text": "Texts",
          "button": "Button",
          "buttonText": "Button text",
          "borderRadius": "Button radius",
          "onlyOTP": "Only OTP screen",
          "errorColor": "Error main color",
          "errorColorBg": "Error background color",
          "errorButtonText": "Button color",
          "accentColor": "Message main color",
          "accentColorBg": "Message background color",
          "moreInfo": "More info",
          "enableFollowEmail": "Enable Follow-up Email",
          "enableFollowEmailBackoffice": "Enable Backoffice documentation Email",
          "withLogo": "With logo",
          "urlLogo": "Url logo",
          "personalData": "Personal data",
          "otpSegSocial": "OTP Social Sec",
          "completed": "Completed",
          "dataError": "Data error",
          "failed": "Failed",
          "abandoned": "Abandoned",
          "setTitle": "Title",
          "titleFontHeight": "Title font size",
          "setDescription": "Description text",
          "dataAssignmentCheck": "Data assignment check",
          "dataAssignmentLabel": "Data assignment text",
          "viewCheckLegalMandatory": "Force to read the legal checks",
          "infiniteLoopInCompleted": "Dont show completed screen",
          "dontShowDataInTerms": "Dont show client data in terms screen",
          "disableRetryPhone": "Disable retry in bad phone",
          "hideCheckLegal": "Hide legal services check",
          "tryPinWithoutSMS": "Try Pin code when don't have SMS",
          "activatePower": "Activate digital power"
        },
        "urls": {
          "title": "Widget links",
          "description": "These are the different urls that you can use for the widget according to your requirements",
          "no_packs_for": "No packs for flow ",
          "no_pack_selected": "You must choose a documentary pack to be able to generate the URL of the widget",
          "user": "User",
          "document_packs": "Document packs",
          "sms_pack": "Document Pack SMS",
          "pin_pack": "Document Pack ClavePIN",
          "link_copied": "Link copied to clipboard"
        },
        "terms": {
          "title": "Terms and conditions flow "
        },
        "policy": {
          "title": "Legal warning"
        },
        "notifications": {
          "title": "Notifications",
          "description": "Configure the url and email where all notifications will be sent"
        },
        "otp": {
          "title": "OTP order",
          "description": "Select the OTP that will be requested first",
          "select_otp": "OTP Portal"
        }
      },
      "loaders": {
        "loading_request": "Loading request",
        "deleting_documents": "Deleting documents",
        "documents_deleted": "Documents deleted succesfuly",
        "documents_not_deleted": "Can not deleted documents",
        "new_request_added": "New request added",
        "link_copied": "Link copied to clipboard"
      }
    }
  }

  export const languages = [
    {
      key: 'es',
      img: 'assets/lang/es.png',
      label: 'Castellano'
    },
    {
      key: 'en',
      img: 'assets/lang/en.png',
      label: 'English'
    }
  ];

  export var localSelectedLanguage = localStorage.getItem('belender-language');
  export var currentTranslation = new BehaviorSubject(translation[localSelectedLanguage]);
  export var currentLanguage = languages.find(p => p.key == localSelectedLanguage);

  export const setCurrentLanguage = function(key) {
    let r = languages.find(p => p.key == key);
    if(r) {
      
      currentLanguage = r;
      localStorage.setItem('belender-language', key);
      currentTranslation.next(this.translation[key]);
      console.log('setCurrentLanguage: ', this.currentTranslation);
      //this.cd.markForCheck();
    }
  }

  export const getCurrentTranslation = function() {
    return currentTranslation.asObservable();
  }

  export const getLanguage = function(key) {
    let r = languages.find(p => p.key == key);
    if(r) {
      return r;
    }
  }
  
  export const getCurrentLanguage = function() {
    return currentLanguage;
  }
  
  export const getAllLanguages = function() {
    return languages;
  }




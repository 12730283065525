import { Injectable, Inject } from '@angular/core';

import { PDF_MIME_TYPE, B64_URL_PREFIX } from './constants';
import { AF_AUTOSCRIPT } from './tokens';
import { AF_MODULE_SCRIPT, AF_SIGNING_ALGORITHMS, AF_SIGNING_FORMATS } from './types';

@Injectable()
export class AutofirmaService {

  public constructor(
    @Inject(AF_AUTOSCRIPT) private __autofirma: AF_MODULE_SCRIPT,
  ) {
    this.__autofirma.cargarAppAfirma();
  }

  /**
   * Executes a request for PDF signatures to the Autofirma client.
   */
  public requestPDFSignature(
    file: Blob,
    algorithm: AF_SIGNING_ALGORITHMS = 'SHA512withRSA'
  ): Promise<Blob> {
    return this.requestSignature(file, algorithm, 'PAdES');
  }

  /**
   * Executes a document signature request to the Autofirma client.
   *
   * NOTE: Please avoid using this method directly, in favor of signing-typed
   * methods.
   */
  public async requestSignature(
    file: Blob,
    algorithm: AF_SIGNING_ALGORITHMS,
    format: AF_SIGNING_FORMATS,
  ): Promise<Blob> {
    return this.__requestSignature(file, algorithm, format)
  }

  private async __requestSignature(
    file: Blob,
    algorithm: AF_SIGNING_ALGORITHMS,
    format: AF_SIGNING_FORMATS
  ): Promise<Blob> {
    this.__checkFileIsPDF(file);

    const b64Data = await this.__convertBinaryToBase64(file);

    return new Promise((resolve, reject) => {
      this.__autofirma.sign(
        b64Data,
        algorithm,
        format,
        "filters=nonexpired:false",
        async (signedDocument: string, cert: string) => {
          try {
            const blob = this.__convertBase64ToBinary(signedDocument);
            resolve(blob);
          } catch (error: any) {
            reject(error);
          }
        },
        (code: string, message: string) => reject(new Error(`${code}: ${message}`)));
    })
  }

  private __checkFileIsPDF(file: Blob) {
    if (file.type !== PDF_MIME_TYPE) {
      throw new Error('Invalid file type, PDF expected.');
    }
  }

  private __convertBinaryToBase64(file: Blob): Promise<string> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        const result = reader.result as string;
        resolve(result.split(',')[1]);
      };

      reader.readAsDataURL(file);
    });
  }

  private __convertBase64ToBinary(base64: string): Promise<Blob> {
    return fetch(B64_URL_PREFIX + base64).then((r) => r.blob());
  }
}

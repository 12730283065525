import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import UuidEncoder from 'uuid-encoder';
import { AppComponent } from 'src/app/app.component';

// Create Base 36 encoder
const encoder = new UuidEncoder('base36');

@Injectable({
    providedIn: 'root'
})
export class UtilsService {

    private loading = new BehaviorSubject<any>(false);

    constructor() { }

    setLoading(b: any) {
        this.loading.next(b);
    }

    getLoading() {
        return this.loading.asObservable();
    }

    public uuidv4() {
        return encoder.encode(this.uuidv4Aux());
    }

    uuidv4Aux() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = (Math.random() * 16) | 0,
                v = c == 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    convertObjectArrayToArray(obj: any) {
        return Object.keys(obj).map(key => {
            const ar = obj[key]
            return ar;
        });
    }

    public generatePassword() {
        var newPassword = "";
        let libraries = [{
            "id": "lowercase",
            "label": "a-z",
            "library": "abcdefghijklmnopqrstuvwxyz",
            "checked": true
          }, {
            "id": "uppercase",
            "label": "A-Z",
            "library": "ABCDEFGHIJKLMNOPWRSTUVWXYZ",
            "checked": true
          }, {
            "id": "numbers",
            "label": "0-9",
            "library": "0123456789",
            "checked": true
          }, {
            "id": "symbols",
            "label": "!-?",
            "library": "!@#$%^&*-_=+\\|:;',.\<>/?~",
            "checked": false
          }]

        let dictionary = [].concat(
            libraries[0].library.split(""),
            libraries[1].library.split(""),
            libraries[2].library.split(""),
            libraries[3].library.split("")
          );
        for (var i = 0; i < 9; i++) {
            newPassword += dictionary[Math.floor(Math.random() * dictionary.length)];
        }
        newPassword = newPassword;
        
        return newPassword;
    }

  
    
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FetchService } from './fetch.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    public financiadorID;
    public userId;
    public userData;
    public bUserData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public userToken: string;
    public logged = false;
    
    constructor() {}

    public setUserToken(type, token) {
        this.userToken = type + ' ' + token;
    }

    public getUserToken() {
        return this.userToken;
    }

    public setUserData(t) {
        this.userData = t;
        this.logged = true;
        this.bUserData.next(t);
    }

    public getUserData() {
        return this.userData;
    }

    public bindUserData() {
        return this.bUserData.asObservable();
    }

    public setUserId(t) {
        this.userId = t;
    }

    public getUserId() {
        return this.userId;
    }
    
    public setFunderId(id) {
        this.financiadorID = id;
    }

    public getFunderId() {
        return this.financiadorID;
    }

    public isLogged() {
        return this.logged;
    }

    public setLogged(f) {
        this.logged = f;
    }

    public getUserRole() {
        return this.userData ? this.userData.role : '';
    }

}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AutofirmaService } from 'ngx-autofirma-lib';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { AuthService } from 'src/services/auth.service';

@Component({
  selector: 'vex-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  animations: [
    fadeInUp400ms,
    fadeInRight400ms,
    scaleIn400ms,
    stagger40ms
  ]
})
export class ProfileComponent implements OnInit {


  file?: File;
  signedFile?: File;
  signedDownloadUrl?: string;

  constructor(public authS: AuthService, private dialog: MatDialog, public _autofirma: AutofirmaService) { }

  ngOnInit(): void {
    
  }

  onChangeFile(event: Event) {
    const fileInput = (event.target as HTMLInputElement)
    if(!fileInput || !fileInput.files) {
      return;
    }
    this.file = fileInput.files[0];
  }

  async onClickAutofirma() {
    if(!this.file) {
      return;
    }

    let [fileName, fileExtension] = this.file.name.split('.');

    let signedBlob = await this._autofirma.requestPDFSignature(this.file);

    let signedFile = new File([signedBlob], fileName + '_signed.' + fileExtension, {type: this.file.type});

    this.signedDownloadUrl = await this.fileToUrl(signedFile);
    this.signedFile = signedFile;
    console.warn(this.signedFile);
   
    const downloadLink = document.createElement("a");

    downloadLink.href = this.signedDownloadUrl;
    downloadLink.download = fileName + '_signed.' + fileExtension;
    downloadLink.click();

  }

  public fileToUrl(file: Blob): Promise<string> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        resolve(reader.result as string);
      };

      reader.readAsDataURL(file);
    });
  }
  
}


export const PDF_MIME_TYPE = 'application/pdf';

/**
 * The URL prefix is utilized to append a base64 PDF file to any `src` property.
 */
export const B64_URL_PREFIX = `data:${PDF_MIME_TYPE};base64,`;

/**
 * This is an empty PDF file intended for testing purposes.
 */
export const B64_EMPTY_PDF = ''
+ 'JVBERi0xLjUKJbXtrvsKNCAwIG9iago8PCAvTGVuZ3RoIDUgMCBSCiAgIC9GaWx0ZXIgL0ZsYXRl'
+ 'RGVjb2RlCj4+CnN0cmVhbQp4nDNUMABCXUMgYWFiqGdhYWluZqKQnMtVyBWoUMgFkjS1NAXJKRSl'
+ 'KoQr5AElgAJ6BhYQjSBNZhZQ3UAGUKd+ooFCerGCfoWZgks+VyAQAgAOfBUDCmVuZHN0cmVhbQpl'
+ 'bmRvYmoKNSAwIG9iagogICA4NQplbmRvYmoKMyAwIG9iago8PAogICAvRXh0R1N0YXRlIDw8CiAg'
+ 'ICAgIC9hMCA8PCAvQ0EgMSAvY2EgMSA+PgogICA+PgogICAvWE9iamVjdCA8PCAveDYgNiAwIFIg'
+ 'Pj4KPj4KZW5kb2JqCjIgMCBvYmoKPDwgL1R5cGUgL1BhZ2UgJSAxCiAgIC9QYXJlbnQgMSAwIFIK'
+ 'ICAgL01lZGlhQm94IFsgMCAwIDU5NS4zMDM5MzcgODQxLjg4OTc2NCBdCiAgIC9Db250ZW50cyA0'
+ 'IDAgUgogICAvR3JvdXAgPDwKICAgICAgL1R5cGUgL0dyb3VwCiAgICAgIC9TIC9UcmFuc3BhcmVu'
+ 'Y3kKICAgICAgL0kgdHJ1ZQogICAgICAvQ1MgL0RldmljZVJHQgogICA+PgogICAvUmVzb3VyY2Vz'
+ 'IDMgMCBSCj4+CmVuZG9iago3IDAgb2JqCjw8IC9MZW5ndGggOCAwIFIKICAgL0ZpbHRlciAvRmxh'
+ 'dGVEZWNvZGUKICAgL1R5cGUgL1hPYmplY3QKICAgL1N1YnR5cGUgL0ltYWdlCiAgIC9XaWR0aCAx'
+ 'NjUzCiAgIC9IZWlnaHQgMjMzOAogICAvQ29sb3JTcGFjZSAvRGV2aWNlR3JheQogICAvSW50ZXJw'
+ 'b2xhdGUgdHJ1ZQogICAvQml0c1BlckNvbXBvbmVudCAxCj4+CnN0cmVhbQp4nO3BAQ0AAADCoPdP'
+ 'bQ8HFAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'
+ 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'
+ 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'
+ 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'
+ 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'
+ 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'
+ 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'
+ 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'
+ 'AAAAAAAAAAAAAAAAAAAAAPBpYucAAQplbmRzdHJlYW0KZW5kb2JqCjggMCBvYmoKICAgNDkxCmVu'
+ 'ZG9iago2IDAgb2JqCjw8IC9MZW5ndGggOSAwIFIKICAgL0ZpbHRlciAvRmxhdGVEZWNvZGUKICAg'
+ 'L1R5cGUgL1hPYmplY3QKICAgL1N1YnR5cGUgL0ltYWdlCiAgIC9XaWR0aCAxNjUzCiAgIC9IZWln'
+ 'aHQgMjMzOAogICAvQ29sb3JTcGFjZSAvRGV2aWNlR3JheQogICAvSW50ZXJwb2xhdGUgdHJ1ZQog'
+ 'ICAvQml0c1BlckNvbXBvbmVudCAxCiAgIC9TTWFzayA3IDAgUgo+PgpzdHJlYW0KeJztwQENAAAA'
+ 'wqD3T20PBxQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'
+ 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'
+ 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'
+ 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'
+ 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'
+ 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'
+ 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'
+ 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'
+ 'AAAAAAAAAAAAAAAAAAAAAAAAAADwaWLnAAEKZW5kc3RyZWFtCmVuZG9iago5IDAgb2JqCiAgIDQ5'
+ 'MQplbmRvYmoKMSAwIG9iago8PCAvVHlwZSAvUGFnZXMKICAgL0tpZHMgWyAyIDAgUiBdCiAgIC9D'
+ 'b3VudCAxCj4+CmVuZG9iagoxMCAwIG9iagogPDwgPj4KZW5kb2JqCjExIDAgb2JqCjw8IC9UeXBl'
+ 'IC9DYXRhbG9nCiAgIC9QYWdlcyAxIDAgUgo+PgplbmRvYmoKeHJlZgowIDEyCjAwMDAwMDAwMDAg'
+ 'NjU1MzUgZiAKMDAwMDAwMjAwNCAwMDAwMCBuIAowMDAwMDAwMjk4IDAwMDAwIG4gCjAwMDAwMDAx'
+ 'OTggMDAwMDAgbiAKMDAwMDAwMDAxNSAwMDAwMCBuIAowMDAwMDAwMTc3IDAwMDAwIG4gCjAwMDAw'
+ 'MDEyNTkgMDAwMDAgbiAKMDAwMDAwMDUzMCAwMDAwMCBuIAowMDAwMDAxMjM3IDAwMDAwIG4gCjAw'
+ 'MDAwMDE5ODIgMDAwMDAgbiAKMDAwMDAwMjA2OSAwMDAwMCBuIAowMDAwMDAyMTg2IDAwMDAwIG4g'
+ 'CnRyYWlsZXIKPDwgL1NpemUgMTIKICAgL1Jvb3QgMTEgMCBSCiAgIC9JbmZvIDEwIDAgUgo+Pgpz'
+ 'dGFydHhyZWYKMjIzOQolJUVPRgp4cmVmDQowIDENCjAwMDAwMDAwMDEgNjU1MzUgZg0KMTAgMQ0K'
+ 'MDAwMDAwMDAwMCAwMDAwMSBmDQp0cmFpbGVyDQo8PC9TaXplIDEyIC9Sb290IDExIDAgUiAvUHJl'
+ 'diAyMjM5ID4+IA0Kc3RhcnR4cmVmDQoyNTY1DQolJUVPRg0K';

/**
 * Empty PDF file data represented as URL encoded base64 string.
 */
export const B64_EMPTY_PDF_URL = B64_URL_PREFIX + B64_EMPTY_PDF;


import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NotificationsService } from './notifications.service';
import { NewTrelloCard, TrelloCard } from 'src/entity/trello-card';

@Injectable({
    providedIn: 'root'
})
export class TrelloService {

    // Api key de Belender
    apiKey = '';

    // Token de Belender
    token = '';

    // Board  (Belender)
    boardKey = '';

    basicUrl = 'https://api.trello.com/1/';


    testUrl = 'https://';
    personalUrl = 'https://';

    constructor(public utils: UtilsService, private http: HttpClient, private notis: NotificationsService) { 
        
    }

    getTrelloCards() {
        return this.http.get(this.basicUrl + 'boards/' + this.boardKey + '/cards?key=' + this.apiKey + '&token=' + this.token);
    }

    getInfoCard(listKey: string) {
        return this.http.get(this.basicUrl + 'lists/' + listKey + '?key=' + this.apiKey + '&token=' + this.token);
    }

    updateCard(card: TrelloCard) {
        return this.http.put(this.basicUrl + 'cards/' + card.id + '?key=' + this.apiKey + '&token=' + this.token, card);
    }

    addCard(card: NewTrelloCard) {
        return this.http.post(this.basicUrl + 'cards?idList=' + card.idList + '&keepFromSource=all&key=' + this.apiKey + '&token=' + this.token, card);
    }
}

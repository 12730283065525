
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})


export class HandleErrorsService {

    ErrorsMap = {
        birth_day: {
            status: "error",
            message: "Fecha de nacimiento incorrecta",
            code: 422
        },
        phone: {
            status: "error",
            message: "Teléfono incorrecto",
            code: 422
        },
        document_number: {
            message: "Número de documento inválido",
            code: 422,
            status: "error"
        },
        document_support: {
            message: "Número de soporte inválido",
            code: 422,
            status: "error"
        },
        generic: {
            status: "error",
            message: "Error desconocido",
            code: 0
        },
        name: {
            status: "error",
            message: "El nombre ya ha sido utilizado para otro documento",
            code: 422
        }
    }

    constructor() {}

    public getError(flow, endpoint, number) {
        let n = '' + number;
        return this.ErrorsMap[flow] && this.ErrorsMap[flow][endpoint] && this.ErrorsMap[flow][endpoint][n] ? this.ErrorsMap[flow][endpoint][n] : this.getGenericMessage();
    }

    async getGenericMessage() {
        return this.ErrorsMap.generic;
    }

    async handleResponse(response) {
        const self = this;
        let r = await response;
        if(r.status >= 200 && r.status < 300) {
            return response.json();
        } else if (r.status == 422) {
            let obj = await response.json();
            let mssg = '';
            let arr = Object.keys(obj.message);
            if(arr && arr.length) {
                arr.forEach(l => {
                    if(self.ErrorsMap[l]) {
                        mssg += ' ' + self.ErrorsMap[l].message;
                    } else {
                        mssg += ' ' + obj.message[l];
                    }
                });
            }
            let re = {
                message: mssg || obj.message,
                code: 422,
                status: "error"
            }
            return re;
        } else {
            let obj = await response.json();
            let re = this.ErrorsMap.generic;
            if(obj.message) {
                re.message = obj.message;
            }
            re.code = +r.status;
            return re;
        }
    }

    async handleFileResponse(response) {
        
        let r = await response;
        return r.body;
    }

}




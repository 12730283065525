import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';
import { getDatabase, onValue, ref, set } from "firebase/database";
import { BehaviorSubject } from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import { DialogComponent } from 'src/@vex/components/dialog/dialog.component';
import { SnackBarComponent } from './components/mat-snackbar.component';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {

    _userNotifications: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    countNotifications = 0; 
    public userID = '123';

    constructor(public snackBar: MatSnackBar, public dialog: MatDialog) { }

    public showToast(mssg, duration?, type?, title?) {
      if(title) {
        mssg = `<h3>${title}</h3> <p>${mssg}</p>`
      }
        this.snackBar.openFromComponent(SnackBarComponent, {
          duration: duration || 4000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          panelClass: type || '',
          data: mssg
        });
    }

    public showHtmlToast(html, duration?, type?) {
      
        this.snackBar.openFromComponent(SnackBarComponent, {
          duration: duration || 4000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          panelClass: type || '',
          data: html
        });
    }

    public showAlert(title, mssg?, icon?) {
    
          Swal.fire({  
            title: title,  
            text: mssg || '',  
            icon: icon || 'info',  
            showCancelButton: false,  
            confirmButtonText: 'OK' 
          });

          /*
          .then((result) => {  
            if (result.value) {  
              Swal.fire(  
                'Deleted!',  
                'Your imaginary file has been deleted.',  
                'success'  
              )  
            } else if (result.dismiss === Swal.DismissReason.cancel) {  
              Swal.fire(  
                'Cancelled',  
                'Your imaginary file is safe :)',  
                'error'  
              )  
            }  
          });
          */
    }

    public showAlertWhithAction(title, mssg?, icon?, func?) {
    
      Swal.fire({  
        title: title,  
        text: mssg || '',  
        icon: icon || 'info',  
        showCancelButton: true,  
        confirmButtonText: 'Cambiar ahora',
        cancelButtonText: 'Luego la cambio'
      })
      .then((result) => {  
        console.warn(result);
        func(result)
      });
      
    }

    public showAreYouSure(title, mssg, cancelText, confirmText, func) {
    
      Swal.fire({  
        title: title,  
        text: mssg || '',  
        icon: 'warning',  
        showCancelButton: true,
        confirmButtonText: confirmText || 'Si',
        cancelButtonText: cancelText || 'No'
      })
      .then((result) => {  
        if (result.value) {  
          return func(true);
        } else if (result.dismiss === Swal.DismissReason.cancel) {  
          return func(false);
        }  
      });
}

    public showPrompt(mssg) {
      return new Promise((resolve, reject) => {
        Swal.fire({
          title: mssg,
          input: 'text',
          inputAttributes: {
            autocapitalize: 'off'
          },
          showCancelButton: true,
          confirmButtonText: 'Enviar',
          allowOutsideClick: true
        }).then((result) => {
          if (result.isConfirmed) {
            resolve(result.value)
          } else {
            reject(false)
          }
        })
      })
      
    }

    bindFunderNotifications(userID) {
      this.userID = userID;
      const db = getDatabase();
      const starCountRef = ref(db,  `notifications/${userID}`);
      onValue(starCountRef, (snapshot) => {
        let n = snapshot.val();
        let r = n ? this.convertObjectArrayToArray(n) : [];
        if(n && this.countNotifications < r.length) {
          this.showToast('Nueva notificacion', 5000, 'info')
        }
        this.countNotifications = +(r.length);
        this._userNotifications.next(r && r.length ? r.sort((a,b) => this.sortByDate(a,b)) : []);
      });
  }

  sortByDate(a,b) {
    return +(new Date(a.date).getTime()) - +(new Date(b.date).getTime());
  }

  convertObjectArrayToArray(obj: any ) {
    return Object.keys(obj).map(key => {
      const ar = obj[key];
      ar['key'] = key;
      return ar;
    });
  }

  subscribeMyNotifications() {
      return this._userNotifications.asObservable();
  }

  markAsReaded(notificationID) {
    const db = getDatabase();
    set(ref(db, `notifications/${this.userID}/${notificationID}/readed`), true);
  }

  openDialog(data?): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: data || null,
      width: '400px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  deleteNotification(notificationID) {
    const db = getDatabase();
    set(ref(db, `notifications/${this.userID}/${notificationID}`), null).then(d => {
      this.showToast('Notificación eliminada', 4000, 'danger');
    })
  }
}


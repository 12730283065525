

import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';

@Component({
  selector: 'snack-bar-component-example-snack',
  templateUrl: 'mat-snackbar.component.html',
})

export class SnackBarComponent {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: string) { }
}

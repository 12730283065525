import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../services/translation.service';
import { solicitudesLabels } from '../static-data/aio-table-data';

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {

  constructor(private translate: TranslationService) {
  
  }
 
  transform(value: any, page: string): any {
    let r = solicitudesLabels.find(p => p.text == value);
    if(r) {
      return r.label;
    } else {
      return this.translate.data[page] && this.translate.data[page][value] ? this.translate.data[page][value] : '***' + value + '***';
    }
    
    //return this.getValueFromKey(page, value) || '***' + value + '***';
  }

 

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { FetchService } from './fetch.service';

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    
    constructor(private routes: Router, private localFetch: FetchService, public authS: AuthService, private http: HttpClient) {
        
    }

    public login(user) {
        return new Promise((resolve, reject) => {
            if(user) {
                this.http.post(environment.baseUrl + '/login',user).subscribe((d: any) => {
                    if(d && d.status && d.status == 'success') {
                        localStorage.setItem('belender-user', JSON.stringify(user));
                        this.setUserData(d)
                        resolve(d);
                    } else {
                        reject(false);
                    }
                }, e => {
                    reject(false)
                });
            } else {
                reject(false);
            }
        });
    }

    setUserData(user) {
        this.authS.setUserData(user.data);
        this.authS.setUserToken(user.token_type, user.access_token);
        this.authS.setUserId(user.data.user_id);
        this.authS.setFunderId(user.data.funder_id);
    }

    public tryLocalLogin() {
        let user = localStorage.getItem('belender-user') ? JSON.parse(localStorage.getItem('belender-user')) : null;
        return this.login(user);
    }

    public logout() {
        this.localFetch.postFetch(`${environment.baseUrl}/logout`, {}).then(d => {
            localStorage.removeItem('belender-user');
            this.authS.setUserData(null);
            this.authS.setUserToken('', '');
            this.authS.setUserId('');
            this.authS.setLogged(false);
            this.routes.navigate(['login']);
        }).catch(e => {
            this.routes.navigate(['login']);
        });
    }

    public forgotPassword(obj) {
        return this.localFetch.postFetch(`${environment.baseUrl}/forgot-password`, obj);
    }

    public resetPassword(obj) {
        return this.localFetch.postFetch(`${environment.baseUrl}/reset-password`, obj);
    }

}

import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { getDatabase } from "firebase/database";
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FetchService } from './fetch.service';

@Injectable({
    providedIn: 'root'
})
export class PortalsService {

    db = getDatabase();

    constructor(public utils: UtilsService, private http: HttpClient, private localFetch: FetchService) {}

    getPortals() {
      return new Promise((resolve, reject) => {
        this.localFetch.getFetch(environment.baseUrl + '/portals').then((d: any) => {
            if(d) {
                resolve(d);
            } else {
                reject(false);
            }
        }, e => {
            reject(false)
        });
    });
    }

  
}

import { mergeDeep } from '../utils/merge-deep';
import { VexConfigName } from './config-name.model';
import { VexConfig } from './vex-config.interface';
import { ColorSchemeName } from './colorSchemeName';
import { colorVariables } from '../components/config-panel/color-variables';

const defaultConfig: VexConfig = {
  id: VexConfigName.apollo,
  name: 'Belender',
  logoUrl: 'assets/img/demo/logo.svg',
  hostname: 'localhost',
  style: {
    colorScheme: ColorSchemeName.default,
    colors: {
      primary: colorVariables.green
    },
    borderRadius: {
      value: 0.25,
      unit: 'rem'
    },
    button: {
      borderRadius: {
        value: 1.75,
        unit: 'rem'
      },
    }
  },
  direction: 'ltr',
  imgSrc: '',
  layout: 'horizontal',
  boxed: false,
  sidenav: {
    title: 'Belender',
    imageUrl: 'assets/img/demo/logo.svg',
    showCollapsePin: true,
    user: {
      visible: true
    },
    search: {
      visible: true
    },
    state: 'collapsed'
  },
  toolbar: {
    fixed: true,
    user: {
      visible: true
    }
  },
  navbar: {
    position: 'below-toolbar'
  },
  footer: {
    visible: true,
    fixed: true
  }
};

export const configs: VexConfig[] = [
  defaultConfig,
  mergeDeep({ ...defaultConfig }, {
    id: 'belender-dev',
    name: 'Belender',
    logoUrl: 'assets/img/demo/logo.png',
    imgSrc: 'assets/img/demo/logo.svg',
    hostname: 'backoffice.dev.belender.net',
    sidenav: {
      title: 'Belender',
      imageUrl: 'assets/img/demo/logo.svg',
      showCollapsePin: true,
      user: {
        visible: true
      },
      search: {
        visible: true
      },
      state: 'collapsed'
    },
    style: {
      colorScheme: ColorSchemeName.default,
      colors: {
        primary: colorVariables.green
      },
      borderRadius: {
        value: 0.25,
        unit: 'rem'
      },
      button: {
        borderRadius: undefined
      }
    },
  }),
  mergeDeep({ ...defaultConfig }, {
    id: 'belender-qa',
    name: 'Belender QA',
    logoUrl: 'assets/img/demo/logo.png',
    imgSrc: 'assets/img/demo/logo.svg',
    hostname: 'backoffice.qa.belender.net',
    sidenav: {
      title: 'Belender QA',
      imageUrl: 'assets/img/demo/logo.png',
      showCollapsePin: true,
      user: {
        visible: true
      },
      search: {
        visible: true
      },
      state: 'collapsed'
    },
    style: {
      colorScheme: ColorSchemeName.default,
      colors: {
        primary: colorVariables.green
      },
      borderRadius: {
        value: 0.25,
        unit: 'rem'
      },
      button: {
        borderRadius: undefined
      }
    },
  }),
  mergeDeep({ ...defaultConfig }, {
    id: 'belender-prod',
    name: 'Belender',
    logoUrl: 'assets/img/demo/logo.png',
    imgSrc: 'assets/img/demo/logo.svg',
    hostname: 'backoffice.belender.net',
    sidenav: {
      title: 'Belender',
      imageUrl: 'assets/img/demo/logo.svg',
      showCollapsePin: true,
      user: {
        visible: true
      },
      search: {
        visible: true
      },
      state: 'collapsed'
    },
    style: {
      colorScheme: ColorSchemeName.default,
      colors: {
        primary: colorVariables.green
      },
      borderRadius: {
        value: 0.25,
        unit: 'rem'
      },
      button: {
        borderRadius: undefined
      }
    },
  }),
  
  mergeDeep({ ...defaultConfig }, {
    id: 'bcds',
    name: 'BC Digital dev',
    logoUrl: 'assets/img/logos/bcds/bcds.png',
    logoTipoUrl: 'assets/img/logos/bcds/bcds-logo.png',
    imgSrc: 'assets/img/demo/logo.svg',
    hostname: 'backoffice.dev.opendata.bcds.net',
    sidenav: {
      title: 'BC Digital',
      imageUrl: 'assets/img/logos/bcds/bcds.png',
      showCollapsePin: true,
      user: {
        visible: true
      },
      search: {
        visible: true
      },
      state: 'collapsed'
    },
    style: {
      colorScheme: ColorSchemeName.default,
      colors: {
        primary: colorVariables['blue-bcds']
      },
      borderRadius: {
        value: 0.5,
        unit: 'rem'
      },
      button: {
        borderRadius: 10,
        unit: 'rem'
      }
    },
  }),

  mergeDeep({ ...defaultConfig }, {
    id: 'bcds',
    name: 'BC Digital',
    logoUrl: 'assets/img/logos/bcds/bcds.png',
    logoTipoUrl: 'assets/img/logos/bcds/bcds-logo.png',
    imgSrc: 'assets/img/demo/logo.svg',
    hostname: 'backoffice.opendata.bcds.net',
    sidenav: {
      title: 'BC Digital',
      imageUrl: 'assets/img/logos/bcds/bcds.png',
      showCollapsePin: true,
      user: {
        visible: true
      },
      search: {
        visible: true
      },
      state: 'collapsed'
    },
    style: {
      colorScheme: ColorSchemeName.default,
      colors: {
        primary: colorVariables['blue-bcds']
      },
      borderRadius: {
        value: 0.5,
        unit: 'rem'
      },
      button: {
        borderRadius: 10,
        unit: 'rem'
      }
    },
  })
];

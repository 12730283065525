import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { getDatabase, ref, child, get, set } from "firebase/database";
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FetchService } from './fetch.service';

@Injectable({
    providedIn: 'root'
})
export class FlowsService {

    db = getDatabase();

    constructor(public utils: UtilsService, private http: HttpClient, private localFetch: FetchService) {}

    getFlows() {
      return new Promise((resolve, reject) => {
        this.localFetch.getFetch(environment.baseUrl + '/flows').then((d: any) => {
            if(d) {
                resolve(d);
            } else {
                reject(false);
            }
        }, e => {
            reject(false)
        });
    });
    }

    getFlowsByUserID(financiadorId) {
      return new Promise((resolve, reject) => {
        this.localFetch.getFetch(environment.baseUrl + '/users/' + financiadorId + '/flows').then((d: any) => {
            if(d) {
                resolve(d);
            } else {
                reject(d);
            }
        }).catch( e => {
            reject(e)
        });
      });
        
    }

    getAllFlows() {
      return new Promise((resolve, reject) => {
        this.localFetch.getFetch(environment.baseUrl + '/flows').then((d: any) => {
            if(d) {
                resolve(d);
            } else {
                reject(d);
            }
        }).catch( e => {
            reject(e)
        });
      });
    }


    getFlowsByFunderID(funderId) {

      return new Promise((resolve, reject) => {
        this.localFetch.getFetch(environment.baseUrl + '/funders/' + funderId + '/flows').then((d: any) => {
            if(d) {
              console.error(d);
                resolve(d);
            } else {
                reject(d);
            }
        }).catch( e => {
            reject(e)
        });
      });
    }

    getAllFlowsByFunderID(funderId) {
      return new Promise((resolve, reject) => {
        this.localFetch.getFetch(environment.baseUrl + '/funders/' + funderId + '/all-flows').then((d: any) => {
            if(d) {
              console.error(d);
                resolve(d);
            } else {
                reject(d);
            }
        }).catch( e => {
            reject(e)
        });
      });
    }

    enableFlowToFunder(funderId, flowId) {

      return new Promise((resolve, reject) => {
        this.localFetch.postFetch(environment.baseUrl + '/funders/' + funderId + '/flows/' + flowId, {}).then((d: any) => {
            if(d) {
              console.error(d);
                resolve(d);
            } else {
                reject(d);
            }
        }).catch( e => {
            reject(e)
        });
      });
    }

    disableFlowToFunder(funderId, flowId) {

      return new Promise((resolve, reject) => {
        this.localFetch.deleteFetch(environment.baseUrl + '/funders/' + funderId + '/flows/' + flowId).then((d: any) => {
            if(d) {
              console.error(d);
                resolve(d);
            } else {
                reject(d);
            }
        }).catch( e => {
            reject(e)
        });
      });
    }

    getFlowsByFunder(funderId) {

      return new Promise((resolve, reject) => {
        this.localFetch.getFetch(environment.baseUrl + '/funders/' + funderId + '/flows').then((d: any) => {
            if(d) {
                resolve(d);
            } else {
                reject(d);
            }
        }).catch( e => {
            reject(e)
        });
      });
    }
}

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LayoutService } from '../../@vex/services/layout.service';
import { filter, map, startWith } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { checkRouterChildsData } from '../../@vex/utils/check-router-childs-data';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ConfigService } from '../../@vex/config/config.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SidebarComponent } from '../../@vex/components/sidebar/sidebar.component';
import { LoginService } from 'src/services/login.service';


@UntilDestroy()
@Component({
  selector: 'vex-custom-layout',
  templateUrl: './custom-layout.component.html',
  styleUrls: ['./custom-layout.component.scss']
})
export class CustomLayoutComponent implements OnInit, OnDestroy {

  sidenavCollapsed$ = this.layoutService.sidenavCollapsed$;
  isFooterVisible$ = this.configService.config$.pipe(map(config => config.footer.visible));
  isDesktop$ = this.layoutService.isDesktop$;

  toolbarShadowEnabled$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    startWith(null),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, data => data.toolbarShadowEnabled))
  );

  @ViewChild('configpanel', { static: true }) configpanel: SidebarComponent;

  countTime = 0;
  inactiveTimeForLogout = 60 * 10; // In seconds
  timerInterval;

  constructor(private layoutService: LayoutService,
              private configService: ConfigService,
              private loginS: LoginService,
              private breakpointObserver: BreakpointObserver,
              private router: Router) {
                this.initInactivityTimer();
               }

  ngOnInit() {
    this.layoutService.configpanelOpen$.pipe(
      untilDestroyed(this)
    ).subscribe(open => open ? this.configpanel.open() : this.configpanel.close());
  }

  ngOnDestroy() {
    this.timerInterval && clearInterval(this.timerInterval);
  }

  initInactivityTimer() {
    window.addEventListener('mousemove', ()=> {this.resetTimer(this)}, false);
    window.addEventListener('mousedown', ()=> {this.resetTimer(this)}, false);
    window.addEventListener('keypress', ()=> {this.resetTimer(this)}, false);
    window.addEventListener('DOMMouseScroll', ()=> {this.resetTimer(this)}, false);
    window.addEventListener('mousewheel', ()=> {this.resetTimer(this)}, false);
    window.addEventListener('touchmove', ()=> {this.resetTimer(this)}, false);
    window.addEventListener('MSPointerMove', ()=> {this.resetTimer(this)}, false);

    this.initInterval();
    
  }

  initInterval() {
    const self = this;
    this.timerInterval = setInterval(() => {
      self.checkTime();
    }, 1000)
  }

  checkTime() {
    const self = this;
    self.countTime++;
    if (self.countTime > self.inactiveTimeForLogout) {
      console.log('logout');
      clearInterval(self.timerInterval)
      this.loginS.logout();
    }
  }

  resetTimer(context) {
    context.countTime = 0;
  }
}

<ng-container >
    
    <router-outlet></router-outlet> 

</ng-container>


<div *ngIf="loading || systemLoading" class="loading-wrapper">
    <mat-progress-spinner diameter="50" mode="indeterminate" color="primary"></mat-progress-spinner> 
    
    <p class="mt-6 text-center text-white dark:text-white" >{{loading}}</p>
</div>

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { VexRoutes } from '../@vex/interfaces/vex-route.interface';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { CanActivateTeam } from 'src/services/canActivateRoute.service';
import { CanActivateAdmin } from 'src/services/canActivateAdmin.service';
import { CanActivateBackoffice } from 'src/services/canActivateBackoffice.service';
import { ProfileComponent } from './pages/autofirma/profile.component';


const routes: VexRoutes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/auth/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
  },
  {
    path: 'reset-user-password/:userId/:token',
    loadChildren: () => import('./pages/auth/reset-user-password/reset-password.module').then(m => m.ResetPasswordModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/auth/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },
  {
    path: '',
    redirectTo: 'solicitudes/all',
    pathMatch: 'full'
  },
  {
    path: '',
    component: CustomLayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard-analytics.module').then(m => m.DashboardAnalyticsModule),
        canActivate: [CanActivateTeam],
      },
      {
        path: 'stats',
        loadChildren: () => import('./pages/stats/stats.module').then(m => m.StatsModule),
        canActivate: [CanActivateTeam],
      },
      {
        path: 'chat',
        loadChildren: () => import('./pages/chat/chat.module').then(m => m.ChatModule),
        canActivate: [CanActivateTeam],
      },
      {
        path: 'solicitudes',
        canActivate: [CanActivateTeam],
        children: [
          {
            path: 'all',    
            loadChildren: () => import('./pages/requests/all/aio-table.module').then(m => m.AioTableModule)
          },
          {
            path: 'new',
            loadChildren: () => import('./pages/requests/new/form-wizard.module').then(m => m.FormWizardModule),
            data: {
              containerEnabled: true
            }
          },
        ]
      },
      {
        path: 'config',
        canActivate: [CanActivateAdmin],
        children: [
          {
            path: 'customization',
            loadChildren: () => import('./pages/config/customization/customization.module').then(m => m.CustomizationModule),
            
          }
        ]
      },
      {
        path: 'admin-config',
        canActivate: [CanActivateBackoffice],
        children: [
          
          {
            path: 'test',
            loadChildren: () => import('./pages/admin/testing/test-table.module').then(m => m.TestTableModule),
          },
          {
            path: 'flows',
            loadChildren: () => import('./pages/admin/flows/flows.module').then(m => m.FlowsModule),
          },
          {
            path: 'packs',
            loadChildren: () => import('./pages/admin/packs-documents/packs.module').then(m => m.PacksModule),
          },
          {
            path: 'users',
            loadChildren: () => import('./pages/admin/users/users.module').then(m => m.UsersModule)
          },
          {
            path: 'validation',
            loadChildren: () => import('./pages/admin/validation/validation.module').then(m => m.ValidationModule)
          },
          {
            path: 'certificates',
            loadChildren: () => import('./pages/admin/certificates/certificates.module').then(m => m.CertificatesModule)
          },
          {
            path: 'power-activate',
            loadChildren: () => import('./pages/admin/power-activate/power-activate.module').then(m => m.PowerActivateModule)
          },
          {
            path: 'panic-button',
            loadChildren: () => import('./pages/admin/panic-button/panic-button.module').then(m => m.PanicButtonModule)
          },
        ]
      },
      {
        path: 'kyc',
        canActivate: [CanActivateBackoffice],
        children: [
          
          {
            path: 'custom',
            loadChildren: () => import('./pages/admin/kyc-custom/kyc-custom.module').then(m => m.KycCustomModule),
          },
          {
            path: 'form',
            loadChildren: () => import('./pages/admin/kyc-form/kyc-form.module').then(m => m.KycFormModule),
          },
        ]
      },
      {
        path: 'help',
        loadChildren: () => import('./pages/others/help-center/help-center.module').then(m => m.HelpCenterModule),
        canActivate: [CanActivateTeam],
      },
      
      {
        path: 'profile',
        loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule),
        canActivate: [CanActivateTeam],
      },
      {
        path: 'autofirma',
        component: ProfileComponent
      },
      {
        path: 'test',
        loadChildren: () => import('./pages/admin/testing/test-table.module').then(m => m.TestTableModule),
        canActivate: [CanActivateAdmin],
      },
      
      {
        path: 'ui',
        children: [
          {
            path: 'components',
            loadChildren: () => import('./pages/others/ui/components/components.module').then(m => m.ComponentsModule),
          },
          {
            path: 'forms/form-elements',
            loadChildren: () => import('./pages/others/ui/forms/form-elements/form-elements.module').then(m => m.FormElementsModule),
            data: {
              containerEnabled: true
            }
          },
          {
            path: 'forms/form-wizard',
            loadChildren: () => import('./pages/others/ui/forms/form-wizard/form-wizard.module').then(m => m.FormWizardModule),
            data: {
              containerEnabled: true
            }
          },
          {
            path: 'icons',
            loadChildren: () => import('./pages/others/ui/icons/icons.module').then(m => m.IconsModule)
          },
        ]
      },
      {
        path: 'forbiden',
        loadChildren: () => import('./pages/errors/error-500/error-500.module').then(m => m.Error500Module)
      },
      {
        path: '**',
        loadChildren: () => import('./pages/errors/error-404/error-404.module').then(m => m.Error404Module)
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    preloadingStrategy: QuicklinkStrategy,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })

  ],
  exports: [RouterModule, QuicklinkModule],
  providers: [CanActivateTeam, CanActivateAdmin, CanActivateBackoffice]
})
export class AppRoutingModule {
}
